.meal-planner {
  max-width: 100%;
  padding: 10px;
  overflow-x: hidden;
}

.macro-goals {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.macro-goal {
  text-align: center;
  flex: 1 1 calc(25% - 10px);
  min-width: 70px;
  margin-bottom: 10px;
}

.macro-goal svg {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #6c757d;
}

.macro-goal p {
  font-size: 1em;
  font-weight: bold;
  color: #007bff;
  margin: 0;
}

.macro-goals h3 {
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #333;
}

.macro-goals p {
  font-size: 1.1em;
  font-weight: bold;
  color: #007bff;
}

.macro-goals svg {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #6c757d;
}

.dietary-preferences {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.dietary-preferences h3 {
  margin-bottom: 10px;
  color: #333;
}

.dietary-preferences ul {
  list-style-type: none;
  padding: 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.dietary-preferences li {
  margin-bottom: 5px;
}

.edit-preferences-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
}

.meal-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.meal-card h3 {
  margin-bottom: 5px;
  font-size: 1.1em;
  color: #333;
}

.meal-timing {
  font-size: 0.8em;
  color: #666;
  margin-bottom: 5px;
}

.meal-macros {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.calories-item {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.calories-item svg {
  margin-right: 5px;
}

.macro-items {
  display: flex;
  justify-content: space-between;
}

.macro-item {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-right: 5px;
}

.macro-item:last-child {
  margin-right: 0;
}

.macro-item svg {
  margin-right: 3px;
}

@media (max-width: 768px) {
  .macro-items {
    flex-wrap: wrap;
  }

  .macro-item {
    flex: 1 1 calc(33.33% - 5px);
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .macro-items {
    flex-wrap: nowrap;
  }

  .macro-item {
    flex: 1;
    margin-bottom: 0;
  }
}

.expand-button, .recommend-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
  display: inline-block;
}

.expand-button {
  margin-right: 10px;
}

.recommend-button {
  background-color: #28a745;
}

.meal-details {
  margin-top: 15px;
  font-size: 0.9em;
}

.meal-details h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1em;
}

.meal-details ul, .meal-details ol {
  padding-left: 20px;
  margin-bottom: 15px;
}

.meal-details li {
  margin-bottom: 5px;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .macro-goal {
    flex: 1 1 calc(50% - 10px);
  }

  .dietary-preferences ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  .macro-item {
    flex: 1 1 calc(50% - 5px);
  }

  .meal-details {
    display: flex;
    flex-direction: column;
  }

  .meal-details-section {
    margin-bottom: 15px;
  }

  .meal-details h4 {
    margin-bottom: 8px;
  }

  .meal-details ul, .meal-details ol {
    padding-left: 15px;
  }
}

@media (max-width: 480px) {
  .macro-goal {
    flex: 1 1 100%;
  }

  .macro-item {
    flex: 1 1 100%;
  }

  .expand-button, .recommend-button {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .expand-button {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .macro-goals {
    flex-direction: column;
  }

  .macro-goal.calories {
    border-right: none;
    border-bottom: 1px solid #dee2e6;
    padding-right: 0;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .macro-goal.macros {
    flex-direction: row;
  }
}

.questionnaire {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-card {
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

.question-card h3 {
  margin-bottom: 10px;
  font-size: 1em;
}

.question-card p {
  margin-bottom: 15px;
}

.options {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.option-label {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.option-label input {
  margin-right: 10px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.nav-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.nav-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.macro-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.macro-card {
  flex: 1 1 calc(25% - 10px);
  min-width: 100px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.macro-card h3 {
  font-size: 0.9em;
  margin-bottom: 5px;
  color: #6c757d;
}

.macro-card p {
  font-size: 1.2em;
  font-weight: bold;
  color: #007bff;
  margin: 0;
}

@media (max-width: 768px) {
  .macro-card {
    flex: 1 1 calc(50% - 10px);
  }
}

/* ... rest of the existing styles ... */

.view-previous-plans-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-previous-plans-button svg {
  margin-right: 5px;
}

.view-previous-plans-button:hover {
  background-color: #5a6268;
}

.previous-meal-plans {
  margin-top: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 15px;
}

/* ... rest of the styles ... */

.recommend-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recommend-button:hover {
  background-color: #218838;
}

.recommend-button.regenerating,
.recommend-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.saved-message {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.saved-message svg {
  margin-right: 10px;
}

.previous-meal-plans {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.previous-meal-plans h3 {
  margin-bottom: 15px;
  color: #333;
}

.previous-meal-plan {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.previous-meal-plan h4 {
  margin-bottom: 10px;
  color: #007bff;
}

.close-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.close-button:hover {
  background-color: #5a6268;
}