body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 76px;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
    padding-bottom: 66px;
  }
}

h1 {
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 20px;
  color: #1a1a1a;
  text-align: center;
  width: 100%;
}

.content-area {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  padding: 0 10px;
  margin-bottom: 20px;
  max-height: calc(100vh - 180px);
}

#weight-input {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 15px;
  outline: none;
  transition: border-color 0.3s ease;
}

#weight-input:focus {
  border-color: #007AFF;
  box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.2);
}

#save-button {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  background-color: #007AFF;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

#save-button:hover:not(:disabled) {
  background-color: #005FCC;
}

#save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.weight-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin: 0 auto 8px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.weight-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weight-date-time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.weight-date {
  font-size: 0.9em;
  font-weight: bold;
  color: #333;
}

.weight-time {
  font-size: 0.7em;
  color: #888;
}

.weight-value-container {
  display: flex;
  align-items: center;
}

.weight-value {
  font-size: 1.2em;
  font-weight: bold;
  color: #007AFF;
  margin-right: 5px;
}

.weight-unit {
  font-size: 0.6em;
  color: #666;
}

.edit-icon, .add-weight-icon {
  background: none;
  border: none;
  color: #007AFF;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 5px;
}

.no-log {
  font-size: 0.9em;
  color: #888;
}

/* Bottom Menu Styles */
.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 56px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.bottom-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #8E8E93;
  font-size: 0.7em;
  padding: 8px 0;
}

.bottom-menu-item.active {
  color: #007AFF;
}

.bottom-menu-icon {
  font-size: 1.4em;
  margin-bottom: 4px;
}

.add-weight-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007AFF;
  color: white;
  font-size: 2em;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.add-weight-button:hover {
  background-color: #005FCC;
}

.sign-out-button:hover {
  background-color: #007AFF;
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #007AFF;
  background-color: white;
  color: #007AFF;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button.active {
  background-color: #007AFF;
  color: white;
}

.pagination button:hover {
  background-color: #e6f2ff;
}

.weight-graph {
  width: 100%;
  max-width: 800px;
  margin: 50px auto 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.graph-container {
  width: 100%;
  height: 400px; /* Increased height for desktop */
  margin: 0 auto;
}

@media (max-width: 600px) {
  .weight-graph {
    padding: 0 10px;
  }

  .graph-container {
    height: 300px; /* Square-ish on mobile */
    max-width: 300px; /* Limit width on mobile for square shape */
  }

  .time-range-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .time-range-buttons button {
    margin: 5px;
    flex: 1 0 40%; /* Two buttons per row on very small screens */
  }
}

.time-range-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: nowrap; /* Ensure buttons stay in one line */
}

.time-range-buttons button {
  flex: 1;
  background-color: #f0f0f0;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.9em;
  color: #333;
  cursor: pointer;
  margin: 0 5px;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.time-range-buttons button:first-child {
  margin-left: 0;
}

.time-range-buttons button:last-child {
  margin-right: 0;
}

.time-range-buttons button:hover,
.time-range-buttons button.active {
  background-color: #007AFF;
  color: white;
}

@media (max-width: 600px) {
  .time-range-buttons {
    flex-wrap: wrap;
  }

  .time-range-buttons button {
    flex-basis: calc(50% - 10px);
    margin: 5px;
  }
}

/* Goal weight cards */
.goal-weight-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.goal-weight-card {
  background-color: #e6f2ff;
  border: 2px solid #007AFF;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0; /* This allows the cards to shrink below their content size */
}

.goal-weight-card .goal-icon {
  font-size: 20px;
  color: #007AFF;
  margin-right: 10px;
  flex-shrink: 0;
}

.goal-weight-card .goal-info {
  flex-grow: 1;
  min-width: 0; /* This allows the text to be truncated if necessary */
}

.goal-weight-card h3 {
  margin: 0;
  color: #007AFF;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.goal-weight-card p {
  margin: 5px 0 0;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
}

.profile h2 {
  margin-bottom: 20px;
}

.profile p {
  margin: 10px 0;
}

.sign-out-button {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

.sign-out-button:hover {
  background-color: #0056b3;
}

.weight-input {
  font-size: 1.4em;
  font-weight: bold;
  color: #007AFF;
  width: 60px;
  border: none;
  background: transparent;
  outline: none;
  -moz-appearance: textfield;
  text-align: center;
}

.weight-input::-webkit-outer-spin-button,
.weight-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.save-icon {
  background: none;
  border: none;
  color: #28a745;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 5px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.login-container h1 {
  font-size: 1.5em;
  margin-bottom: 30px;
  color: #333;
}

.status-message {
  font-size: 0.8em;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.status-message.success {
  color: #28a745;
}

.status-message.error {
  color: #dc3545;
}

.goal-weight-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.goal-weight-container h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.method-selection {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.method-selection button,
.goal-weight-container form button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.method-selection button:hover,
.goal-weight-container form button:hover {
  background-color: #005FCC;
}

.method-selection button svg {
  margin-right: 10px;
  font-size: 1.2em;
}

.back-button {
  background: none;
  border: none;
  color: #007AFF;
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 15px;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.back-button svg {
  margin-right: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1em;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #005FCC;
}

.goal-weight-result {
  margin-top: 20px;
  text-align: center;
  background-color: #f0f8ff;
  padding: 15px;
  border-radius: 8px;
}

.goal-weight-result h3 {
  color: #333;
  margin-bottom: 10px;
}

.goal-weight-result p {
  font-size: 1.4em;
  font-weight: bold;
  color: #007AFF;
}

.calories-and-macros {
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f8ff;
  border-radius: 8px;
}

.calories-and-macros h3, .calories-and-macros h4 {
  margin-bottom: 10px;
}

.calories-and-macros ul {
  list-style-type: none;
  padding: 0;
}

.calories-and-macros li {
  margin-bottom: 5px;
}

.theme-button {
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  width: 100%;
}

.theme-button:hover {
  background-color: #0056b3;
}

.theme-button svg {
  margin-right: 8px;
}

.calculate-button {
  width: 100%;
  box-sizing: border-box;
}

.save-button,
.edit-button {
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.save-button:hover,
.edit-button:hover {
  background-color: #0056b3;
}

.current-weight-card {
  background-color: #e6f2ff;
  border: 2px solid #007AFF;
}

.goal-weight-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.goal-weight-edit input {
  width: 80px;
  font-size: 1.2em;
  text-align: center;
  margin-right: 10px;
}

.confirm-edit {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-edit:hover {
  background-color: #218838;
}

.save-button {
  margin-top: 20px;
}

.calculation-result {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.calculation-result h3 {
  color: #007AFF;
  margin-bottom: 15px;
}

.calculation-result p {
  margin-bottom: 10px;
}

.calculation-result ul {
  list-style-type: none;
  padding-left: 0;
}

.calculation-result li {
  margin-bottom: 5px;
}

.ibw-info {
  font-size: 0.9em;
  color: #666;
  text-align: center;
  margin-bottom: 15px;
  font-style: italic;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content .calculation-result {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.modal-content .goal-info-cards {
  margin-top: 20px;
}

.modal-content .info-card {
  background-color: #f8f9fa;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}

.modal-content h3 {
  color: #007AFF;
  margin-bottom: 15px;
  text-align: center;
}

.modal-content p {
  margin-bottom: 10px;
  text-align: center;
}

.saved-goal-data {
  background-color: #f0f8ff;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.saved-goal-data h3 {
  color: #007AFF;
  margin-bottom: 10px;
}

.saved-goal-data p {
  margin-bottom: 5px;
}

.recalculate-button {
  margin-top: 15px;
}

.calories-and-macros {
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f8ff;
  border-radius: 8px;
}

.calories-and-macros h3, .calories-and-macros h4 {
  margin-bottom: 10px;
}

.calories-and-macros ul {
  list-style-type: none;
  padding: 0;
}

.calories-and-macros li {
  margin-bottom: 5px;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .goal-weight-cards {
    flex-direction: row; /* Keep the cards in a row */
  }

  .goal-weight-card {
    padding: 8px;
  }

  .goal-weight-card .goal-icon {
    font-size: 18px;
    margin-right: 8px;
  }

  .goal-weight-card h3 {
    font-size: 12px;
  }

  .goal-weight-card p {
    font-size: 14px;
  }

  .time-range-buttons button {
    padding: 6px 10px;
    font-size: 0.8em;
  }
}

.theme-button {
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  width: 100%;
}

.theme-button:hover {
  background-color: #0056b3;
}

.theme-button svg {
  margin-right: 8px;
}

.calculate-button {
  width: 100%;
  box-sizing: border-box;
}

.save-button,
.edit-button {
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.save-button:hover,
.edit-button:hover {
  background-color: #0056b3;
}

.current-weight-card {
  background-color: #e6f2ff;
  border: 2px solid #007AFF;
}

.goal-weight-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.goal-weight-edit input {
  width: 80px;
  font-size: 1.2em;
  text-align: center;
  margin-right: 10px;
}

.confirm-edit {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-edit:hover {
  background-color: #218838;
}

.save-button {
  margin-top: 20px;
}

.goal-info-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.info-card-row {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.info-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.macros-row {
  flex-wrap: nowrap;
}

.macro-card {
  flex-basis: calc(33.33% - 10px);
  min-width: 0;
}

.info-icon {
  font-size: 24px;
  color: #007AFF;
  margin-right: 15px;
  flex-shrink: 0;
}

.info-content {
  flex-grow: 1;
  min-width: 0;
}

.info-content h4 {
  margin: 0;
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-content p {
  margin: 5px 0 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .info-card-row {
    flex-direction: column;
  }

  .macro-card {
    flex-basis: 100%;
  }
}

.saved-goal-data {
  background-color: #f0f8ff;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.saved-goal-data h3 {
  color: #007AFF;
  margin-bottom: 10px;
}

.saved-goal-data p {
  margin-bottom: 5px;
}

.recalculate-button {
  margin-top: 15px;
}

.save-icon {
  background: none;
  border: none;
  color: #28a745;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 5px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.login-container h1 {
  font-size: 1.5em;
  margin-bottom: 30px;
  color: #333;
}

.status-message {
  font-size: 0.8em;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.status-message.success {
  color: #28a745;
}

.status-message.error {
  color: #dc3545;
}

.goal-weight-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.goal-weight-container h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.method-selection {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.method-selection button,
.goal-weight-container form button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.method-selection button:hover,
.goal-weight-container form button:hover {
  background-color: #005FCC;
}

.method-selection button svg {
  margin-right: 10px;
  font-size: 1.2em;
}

.back-button {
  background: none;
  border: none;
  color: #007AFF;
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 15px;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.back-button svg {
  margin-right: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1em;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #005FCC;
}

.goal-weight-result {
  margin-top: 20px;
  text-align: center;
  background-color: #f0f8ff;
  padding: 15px;
  border-radius: 8px;
}

.goal-weight-result h3 {
  color: #333;
  margin-bottom: 10px;
}

.goal-weight-result p {
  font-size: 1.4em;
  font-weight: bold;
  color: #007AFF;
}

.calories-and-macros {
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f8ff;
  border-radius: 8px;
}

.calories-and-macros h3, .calories-and-macros h4 {
  margin-bottom: 10px;
}

.calories-and-macros ul {
  list-style-type: none;
  padding: 0;
}

.calories-and-macros li {
  margin-bottom: 5px;
}

.theme-button {
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  width: 100%;
}

.theme-button:hover {
  background-color: #0056b3;
}

.theme-button svg {
  margin-right: 8px;
}

.calculate-button {
  width: 100%;
}

.save-button,
.edit-button {
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.save-button:hover,
.edit-button:hover {
  background-color: #0056b3;
}

.current-weight-card {
  background-color: #e6f2ff;
  border: 2px solid #007AFF;
}

.goal-weight-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.goal-weight-edit input {
  width: 80px;
  font-size: 1.2em;
  text-align: center;
  margin-right: 10px;
}

.confirm-edit {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-edit:hover {
  background-color: #218838;
}

.save-button {
  margin-top: 20px;
}

.calculation-result {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.calculation-result h3 {
  color: #007AFF;
  margin-bottom: 15px;
}

.calculation-result p {
  margin-bottom: 10px;
}

.calculation-result ul {
  list-style-type: none;
  padding-left: 0;
}

.calculation-result li {
  margin-bottom: 5px;
}

.ibw-info {
  font-size: 0.9em;
  color: #666;
  text-align: center;
  margin-bottom: 15px;
  font-style: italic;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content .calculation-result {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.modal-content .goal-info-cards {
  margin-top: 20px;
}

.modal-content .info-card {
  background-color: #f8f9fa;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}

.modal-content h3 {
  color: #007AFF;
  margin-bottom: 15px;
  text-align: center;
}

.modal-content p {
  margin-bottom: 10px;
  text-align: center;
}

.saved-goal-data {
  background-color: #f0f8ff;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.saved-goal-data h3 {
  color: #007AFF;
  margin-bottom: 10px;
}

.saved-goal-data p {
  margin-bottom: 5px;
}

.recalculate-button {
  margin-top: 15px;
}

.calories-and-macros ul {
  list-style-type: none;
  padding: 0;
}

.calories-and-macros li {
  margin-bottom: 5px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.cancel-button {
  background-color: #dc3545;
}

.cancel-button:hover {
  background-color: #c82333;
}

.workout-planner-coming-soon {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-top: 20px;
}

.workout-planner-coming-soon h2 {
  color: #007bff;
  margin-bottom: 10px;
}

.workout-planner-coming-soon p {
  font-size: 16px;
  color: #6c757d;
}