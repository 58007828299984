.meal-planner {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.meal-planner h2 {
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}

.meal-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.meal-card:hover {
  transform: translateY(-5px);
}

.meal-card h3 {
  color: #007bff;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.meal-card h4 {
  color: #333;
  margin-bottom: 10px;
}

.meal-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.detail-item {
  display: flex;
  align-items: center;
}

.detail-item svg {
  margin-right: 5px;
  color: #007bff;
}

.macronutrients, .ingredients, .instructions {
  margin-top: 15px;
}

.macro-details {
  display: flex;
  justify-content: space-between;
}

.macro-item {
  display: flex;
  align-items: center;
}

.macro-item svg {
  margin-right: 5px;
  color: #28a745;
}

.ingredients ul, .instructions ol {
  padding-left: 20px;
}

.ingredients li, .instructions li {
  margin-bottom: 5px;
}

.meal-planner-questionnaire {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.question-card {
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column;
}

.option-label {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option-label:hover {
  background-color: #e0e0e0;
}

.option-label input {
  margin-right: 10px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.nav-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.meal-goals-tables, .meal-plan-tables {
  margin-top: 20px;
}

.meal-goals-tables h3, .meal-plan-tables h3 {
  color: #007bff;
  margin-bottom: 10px;
}

.meal-planner table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.meal-planner th, .meal-planner td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.meal-planner th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.meal-planner tr:nth-child(even) {
  background-color: #f9f9f9;
}

.both-meal-plans-table-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.meal-plan-table {
  width: 48%;
}

@media (max-width: 768px) {
  .meal-plan-table {
    width: 100%;
  }
}

.dietary-preferences {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.dietary-preferences h3 {
  margin-bottom: 15px;
}

.dietary-preferences ul {
  list-style-type: none;
  padding: 0;
}

.dietary-preferences li {
  margin-bottom: 10px;
}

.user-preferences {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.user-preferences h3 {
  margin-bottom: 15px;
}

.user-preferences ul {
  list-style-type: none;
  padding: 0;
}

.user-preferences li {
  margin-bottom: 10px;
}

.edit-preferences-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-preferences-button:hover {
  background-color: #0056b3;
}

.theme-button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.previous-meal-plans {
  margin-top: 20px;
}

.previous-meal-plans h3 {
  margin-bottom: 15px;
}

.previous-meal-plan {
  margin-bottom: 30px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.previous-meal-plan h4 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expand-icon {
  margin-left: 10px;
}

.expanded-meal-plan {
  margin-left: 20px;
}

.previous-meal {
  margin-bottom: 15px;
}

.meal-macros {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.meal-macros span {
  font-size: 0.9em;
}

.meal-details-section {
  margin-bottom: 20px;
}

.meal-details-section h4 {
  color: #007bff;
  margin-bottom: 10px;
}

.meal-details-section ul,
.meal-details-section ol {
  padding-left: 20px;
}

.meal-details-section li {
  margin-bottom: 5px;
}

.selection-instruction {
  font-style: italic;
  color: #666;
  margin-bottom: 10px;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
  font-weight: bold;
}